
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/us-en/courses",
      function () {
        return require("private-next-pages/us-en/courses/index.jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/us-en/courses"])
      });
    }
  